import { css, Global, withTheme } from '@emotion/react'
import React from 'react'

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      body {
        color: ${theme.colors.dark.base.hex};
      }

      // the following styles are necessary as they simply would NOT work in theme.js
      .leshen-typography-h4,
      .leshen-typography-h5,
      .leshen-typography-h6,
      h4,
      h5,
      h6 {
        font-family: objektiv-mk2-medium !important;
      }
    `}
  />
))

export default GlobalStyles
