const merge = require('lodash.merge')

// Import the brand level theme so it can be merged.
const brandTheme = require('gatsby-theme-centurylink/theme')

// Merge the brand level theme and the site level theme.
// The second object being passed to the merge function
// is the site level theme. Anything placed here will
// override the brand level theme.
module.exports = merge(brandTheme, {
  colors: {
    primary: '#503296',
    secondary: '#7b8EE1',
    tertiary: '#38C6F4',
    dark: '#3C414B',
    light: '#FFFFFF',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#2D005A',
    background2: '#EBF0F3',
    background3: '#F6F8F9',
    background4: '#F6F8F9',
    background5: '#F6F8F9',
    background6: '#F6F8F9',
  },
  gradients: {
    primary: 'linear-gradient(to right, #503296, #2D005A)',
    secondary: 'linear-gradient(to right, #503296, #2D005A)',
    tertiary: 'linear-gradient(to right, #503296, #2D005A)',
  },
  fonts: {
    headings: {
      family: 'objektiv-mk2-light, Arial',
      sm: {
        size: [24, 40],
        lineHeight: [28, 48],
        weight: 300,
      },
      md: {
        size: [28, 48],
        lineHeight: [32, 56],
        weight: 300,
      },
      lg: {
        size: [32, 52],
        lineHeight: [38, 60],
        weight: 300,
      },
    },
    subheadings: {
      family: 'objektiv-mk2-medium, Arial',
      sm: {
        size: [18, 20],
        lineHeight: [24, 28],
        weight: 500,
      },
      md: {
        size: [20, 24],
        lineHeight: [24, 32],
        weight: 500,
      },
      lg: {
        size: [22, 28],
        lineHeight: [24, 36],
        weight: 500,
      },
    },
    body: {
      family: 'proxima-nova, Arial',
      legal: {
        size: 12,
        lineHeight: 16,
      },
    },
  },
  backgroundColors: {
    light: {
      textColor: '#3C414B',
      // boxShadow: '0 4px 8px #191961',
    },
  },
  components: {
    Button: {
      rounded: 'max',
      variants: {
        primary: {
          color: 'white',
          hoverColor: 'white',
          activeColor: 'white',
        },
        light: {
          textColor: 'black',
        },
      },
    },
    PackageCard: {
      radius: '24px',
      backgroundColor: '#F6F8F9',
      shadow: 0,
      stroke: {
        width: '1px',
        style: 'solid', // CSS value
        color: '#503296',
      },
      label: {
        inside: true,
        backgroundColor: '#38C6F4',
        radius: '32px',
      },
    },
    Price: {
      color: '#503296',
      noDecimal: true,
      dollarSign: {
        size: '24px',
        position: 'top', // top, bottom (default), center
      },
    },
  },
})
