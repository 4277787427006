import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const ChatStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      @media screen and (max-width: ${theme.breakpoints.md}) {
        .Twilio-EntryPoint {
          bottom: 86px !important;
          right: 15px !important;
        }
      }
    `}
  />
))

export default ChatStyles
